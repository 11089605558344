@keyframes opa {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.splash-app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: -ms-flexbox;
  display: flex;
  background: #f2f2f2;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.splash-app svg {
  width: 25%;
  animation: opa 5s ease-in-out 0s infinite alternate;
}